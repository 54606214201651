///////////// BUTLER /////////////
@font-face {
    font-family: 'butlerlight';
    src: url('#{$font-path}butler_light-webfont.woff2') format('woff2'),
         url('#{$font-path}butler_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
///////////// GEOMANSIT /////////////
@font-face {
    font-family: 'geomanistlight';
    src: url('#{$font-path}geomanist-light-webfont.eot');
    src: url('#{$font-path}geomanist-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}geomanist-light-webfont.woff2') format('woff2'),
         url('#{$font-path}geomanist-light-webfont.woff') format('woff'),
         url('#{$font-path}geomanist-light-webfont.ttf') format('truetype'),
         url('#{$font-path}geomanist-light-webfont.svg#geomanistlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'geomanistmedium';
    src: url('#{$font-path}geomanist-medium-webfont.eot');
    src: url('#{$font-path}geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}geomanist-medium-webfont.woff2') format('woff2'),
         url('#{$font-path}geomanist-medium-webfont.woff') format('woff'),
         url('#{$font-path}geomanist-medium-webfont.ttf') format('truetype'),
         url('#{$font-path}geomanist-medium-webfont.svg#geomanistmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

///////////// FONT ICON /////////////
@font-face {
    font-family: 'emilie';
    src: url('#{$font-path}emilie.eot?esu4fn');
    src: url('#{$font-path}emilie.eot?esu4fn#iefix') format('embedded-opentype'),
    	 url('#{$font-path}emilie.ttf?esu4fn') format('truetype'),
    	 url('#{$font-path}emilie.woff?esu4fn') format('woff'),
    	 url('#{$font-path}emilie.svg?esu4fn#emilie') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'emilie' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: "\e906";
}
.icon-check:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e904";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-logo:before {
  content: "\e901";
}