
// center vertically and/or horizontally an absolute positioned element
// @mixin center($xy:xy) {
//   @if $xy == xy {
//     left: 50%;
//     top: 50%;
//     bottom: auto;
//     right: auto;
//     @include transform(translateX(-50%) translateY(-50%));
//   }
//   @else if $xy == x {
//     left: 50%;
//     right: auto;
//     @include transform(translateX(-50%));
//   }
//   @else if $xy == y {
//     top: 50%;
//     bottom: auto;
//     @include transform(translateY(-50%));
//   }
// }

/* -------------------------------- 
   Primary style
-------------------------------- */

body.album,
body.album-backstage,
body.album-video {
  background-color: $color-1;
  background-color: $white;
}

/* -------------------------------- 
   Main Components 
-------------------------------- */

div[data-hijacking="on"] {
  
  @include mq($from: tablet) {
    overflow: hidden;
  }
}

.cd-section {
  height: 100vh;
  height: calc(100vh - 80px);

  &:first-of-type {

    @include mq($from: desktop) {
      height: 100vh;
      padding-top: 80px;
    }
  }

  &:last-of-type {
    
    @include mq($from: desktop) {
      height: calc(100vh - 54px);
    }
  }

  .cd-intro {
    height: 100vh;
    margin-top: 80px;
    background-color: $color-1;
    background-color: rgba($color-1, 0.9);

    text-align: center;
    color: $white;

    @include mq(mobileLandscape, tablet) {
      height: 200vh;
    }

    .no-touch & {
      color: rgba($white, 0.8);
    }

    .cd-intro--content {
      position: relative;
      top: -40px;
      padding: 0 20px;
    }

    .cd-album--title {
      position: relative;
      font-family: $font-base;
      text-transform: uppercase;
      letter-spacing: 0.25em;
      @include font-size(18);
      line-height: 2em;

      .no-touch & {
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      @include mq($from: tablet) {
        @include font-size(24);
        letter-spacing: 0.3em;
      }

      @include mq($from: wide) {
        @include font-size(26);
        letter-spacing: 0.3em;
      }


      &:after {
        display: block;
        content: "";
        width: 12px;
        height: 1px;
        background-color: $white;
        background-color: rgba($white, 0.8);
        position: absolute;
        bottom: -10px;
        left: 50%;
        margin-left: -6px;

        @include mq($from: tablet) {
          width: 20px;
          margin-left: -10px;
        }

        @include mq($from: wide) {
          width: 24px;
          margin-left: -12px;
          bottom: -12px;
        }
      }
    }

    .cd-album--date {
      position: relative;
      font-family: $font-base;
      color: $black;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      @include font-size(18);
      line-height: 2em;

      @include mq($from: tablet) {
        @include font-size(20);
        letter-spacing: 0.3em;
      }

      @include mq($from: wide) {
        @include font-size(22);
        letter-spacing: 0.3em;
      }
    }
  }

  .cd-img {
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include mq($from: desktop) {
      margin-top: 80px;
    }
  }

  [data-animation="scaledown"] & > div,
  [data-animation="parallax"] & > div,
  [data-animation="fixed"] & > div,
  [data-animation="opacity"] & > div {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include mq($from: desktop) {

    [data-hijacking="on"] & {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      > div {
        visibility: visible;
      }
    }

    [data-hijacking="off"] & > div {
      opacity: 0;
    }

    [data-animation="rotate"] & {
      /* enable a 3D-space for children elements */
      // @include perspective(1800px);
    }

    [data-hijacking="on"][data-animation="rotate"] &:not(:first-of-type) {
      // @include perspective-origin(center 0);
    }

    [data-animation="scaleDown"] & > div,
    [data-animation="gallery"] & > div,
    [data-animation="catch"] & > div {
      // box-shadow: 0 0 40px rgba(darken($color-1, 10%), .4);
      box-shadow: 0 0 0 rgba(darken($color-1, 10%), .4);
    }

    [data-animation="opacity"] &.visible > div {
      z-index: 1;
    }
  }
}

.cd-section:last-of-type .cd-img {
  height: calc(100vh - 80px);
}

// @include mq($from: desktop) {
//   .cd-section:first-of-type > div::before {
//     display: none;
//   }
// }

.cd-section > div {

  @include mq($from: desktop) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    backface-visibility: hidden;

    [data-hijacking="on"] & {
      position: absolute;
    }

    [data-animation="rotate"] & {
      transform-origin: center bottom;
    }
  }
}

.cd-back {
  display: block;
  position: fixed;
  top: 90px;
  left: 15px;
  z-index: 10;

  background-color: rgba($white, 0.7);
  padding: 4px 6px 4px 36px;

  border: 1px solid $color-border;
  border-color: rgba($color-border, 0.5);

  text-transform: uppercase;
  letter-spacing: 0.05em;
  @include font-size(11);
  color: $color-arrow;

  transition: color 0.2s ease-in-out;

  @include mq($from: tablet) {
    @include font-size(12);
  }

  @include mq($from: desktop) {
    top: 95px;
    left: 20px;
  }

  &:before {
    font-family: 'emilie' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text-indent: initial;
    display: block;
    color: $white;
    color: $color-arrow;
    @include font-size(24);

    content: "\e902";
    left: 4px;
    top: -1px;
    position: absolute;

    transition: left 0.2s ease-in-out;

    @include mq($from: tablet) {
      @include font-size(26);
    }
  }

  .no-touch &:hover,
  .no-touchevents &:hover,
  .no-touch &:focus,
  .no-touchevents &:focus,
  .touch &:active,
  .touchevents &:active {
    color: darken($color-1, 10%);
    border-color: darken($color-1, 10%);

    &:before {
      color: $color-1;
      left: -5px;

      @include mq($from: desktop) {
        left: -7px;
      }
    }
  }
}

.cd-nav-container {
  top: 10%;
}

.cd-vertical-nav {
  /* lateral navigation */
  position: fixed;
  z-index: 1;
  right: 3%;
  top: 50%;
  top: calc(50% + 40px);
  bottom: auto;
  transform: translateY(-50%);
  display: none;

  a {
    display: block;
    height: 50px;
    width: 50px;
    position: relative;

    /* image replace */
    // overflow: hidden;
    text-indent: 9999px;
    white-space: nowrap;

    background-color: $color-arrow;
    background-color: rgba($color-arrow, 0.1);
    border: 1px solid $color-border;
    border-color: rgba($color-border, 0.2);

    transition: opacity 0.3s ease-in-out;

    @include mq($from: wide) {
      height: 60px;
      width: 60px;
    }

    &:before {
      font-family: 'emilie' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      text-indent: initial;
      display: block;
      color: $color-arrow;
      color: rgba($color-arrow, 0.6);
      @include font-size(29);
      text-align: center;
          
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 1.7em;
      left: 0;

      @include mq($from: wide) {
        @include font-size(35);
      }
    }

    &.cd-prev {
      &:before {
        content: "\e902";
        transform: rotate(90deg);
        bottom: 0;
        transition: bottom 0.3s ease-in-out;
      }

      .no-touch & {
        &:hover {
          opacity: 0.7;

          &:before {
            bottom: 22px;

            @include mq($from: wide) {
              bottom: 28px;
            }
          }
        }
      }
    }

    &.cd-next {
      margin-top: 4px;

      &:before {
        content: "\e903";
        top: 0;
        transform: rotate(90deg);
        transition: top 0.3s ease-in-out;
      }

      .no-touch & {
        &:hover {
          opacity: 0.7;

          &:before {
            top: 22px;

            @include mq($from: wide) {
              top: 28px;
            }
          }
        }
      }
    }

    &.inactive {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s 0s, visibility 0s 0.2s;
    }
  }

  @include mq($from: desktop) {
    display: block;
  }
}

.cd-close {
  display: block;
  position: fixed;
  z-index: 10;
  width: 50px;
  height: 50px;
  top: 10px;
  left: 10px;

  // background-color: red;

  @include mq($from: desktop) {
    width: 54px;
    height: 54px;
    top: 15px;
    left: 15px;
  }

  @include mq($from: wide) {
    width: 60px;
    height: 60px;
    top: 20px;
    left: 20px;
  }

  &:before {
    font-family: 'emilie' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text-indent: initial;
    display: block;
    color: $color-arrow;
    color: rgba($color-arrow, 0.6);
    @include font-size(36);
    text-align: center;
        
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 1.35em;
    top: 0;
    left: 0;
    content: "\e904";
    transform-origin: center center;

    transition: transform 0.3s ease-in-out;

    @include mq($from: desktop) {
      @include font-size(44);
      line-height: 1.25em;
    }

    @include mq($from: wide) {
      @include font-size(50);
      line-height: 1.2em;
    }
  }

  .no-touch & {
    &:hover, &:focus {
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  .touch & {
    &:active {
      &:before {
        transform: rotate(180deg);
      }
    }
  }
}