.hidden { display: none !important;  }

.clear { clear: both; }

.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

.absolute-child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin center($width: null, $height: null) {
	position: absolute;
	top: 50%;
	left: 50%;

	@if not $width and not $height {
		transform: translate(-50%, -50%);
	} @else if $width and $height {
		width: $width;
		height: $height;
		margin: -($width / 2) #{0 0} -($height / 2);
	} @else if not $height {
		width: $width;
		margin-left: -($width / 2);
		transform: translateY(-50%);
	} @else {
		height: $height;
		margin-top: -($height / 2);
		transform: translateX(-50%);
	}
}

@mixin vertical-align() {
	display: table;
	width: 100%;
	height: 100%;
}

@mixin vertical-align-inner() {
	display: table-cell;
	vertical-align: middle;
}

.vertical-align {
	@include vertical-align();
}

.vertical-align-inner {
	@include vertical-align-inner();
}

.to-tablet,
.until-tablet {
	@include mq($from: tablet) {
		display: none !important;
	}
}

.to-desktop,
.until-desktop {
	@include mq($from: desktop) {
		display: none !important;
	}
}

.to-wide,
.until-wide {
	@include mq($from: wide) {
		display: none !important;
	}
}

.to-xwide,
.until-xwide {
	@include mq($from: xwide) {
		display: none !important;
	}
}

.from-tablet {
	@include mq($until: tablet) {
		display: none !important;
	}
}

.from-desktop {
	@include mq($until: desktop) {
		display: none !important;
	}
}

.from-wide {
	@include mq($until: wide) {
		display: none !important;
	}
}

.from-xwide {
	@include mq($until: xwide) {
		display: none !important;
	}
}

%better-icon,
%icon {
	font-family: $font-icon;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#__bs_notify__ {
	width: 170px !important;
	right: 50% !important;
	padding: 10px !important;
	margin-right: -85px !important;
	background-color: rgba(#000, 0.3) !important;
	border-radius: 0 !important;
	font-size: 12px !important;
}