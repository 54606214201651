html { font-size: 62.5%; }

$font-title: 'butlerlight', serif;
$font-base: 'geomanistlight', sans-serif;
$font-bold: 'geomanistmedium', sans-serif;
$font-icon: 'emilie';
$image-path: '/images/';
$font-path: '/fonts/';


$color-1: #aa8a55; //doré
$color-2: #fcbe56; //jaune
$color-3: #D8D4CD; //jaune
$color-4: #edece8; //gris clair
$color-5: #8F7345; //doré foncé

$white: #fff;
$black: #000;

$color-bg:         $white;
// $color-bg-dark:    #D8D4CD;
$color-bg-dark:    #eeece9;
$color-text:       $black;
$color-text-light: $white;

$color-link: $color-1;
$color-link-hover: lighten($color-1, 10%);

$color-border: #4b4b4b;
$color-arrow:  #3d3d3d;

$color-cancel: #C14443;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$grid-gutter-base:    10px;
$grid-gutter-tablet:  10px;
$grid-gutter-desktop: 15px;
$grid-gutter-wide:    15px;