html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
}

body {
	// background-color: $color-bg;
	background-color: $white;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;

	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	
	&.noscroll {
		overflow: hidden;
	}

	&::before {
		/* never visible - this is used in jQuery to check the current MQ */ 
		content: 'mobile';
		display: none;
	}

	@include mq($from: desktop) {
		&::before {
		/* never visible - this is used in jQuery to check the current MQ */ 
		content: 'desktop';
		}
	}

	&.galerie,
	&.reference,
	&.tarif,
	&.equipe,
	&.question {
		padding-top: 80px;
	}
}

	.mfp-with-zoom {
		background-color: rgba(255, 255, 255, .8);

		.mfp-img {
			max-height: auto;
		}
	
	.image-source-link {
		color: #98C3D1;
	}

	.mfp-with-zoom .mfp-container,
	.mfp-with-zoom.mfp-bg {
		opacity: 0;
		-webkit-backface-visibility: hidden;
		/* ideally, transition speed should match zoom duration */
		-webkit-transition: all 0.3s ease-out; 
		-moz-transition: all 0.3s ease-out; 
		-o-transition: all 0.3s ease-out; 
		transition: all 0.3s ease-out;
	}

	.mfp-with-zoom.mfp-ready .mfp-container {
			opacity: 1;
	}
	.mfp-with-zoom.mfp-ready.mfp-bg {
			opacity: 0.8;
	}

	.mfp-with-zoom.mfp-removing .mfp-container, 
	.mfp-with-zoom.mfp-removing.mfp-bg {
		opacity: 0;
	}
	}

/* 
 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   
##    ##   ##     ##  ###   ###  ###   ###  ##     ##  ###   ##   
##         ##     ##  #### ####  #### ####  ##     ##  ####  ##   
##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   
##         ##     ##  ##     ##  ##     ##  ##     ##  ##  ####   
##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   
 ######     #######   ##     ##  ##     ##   #######   ##    ##   
*/
.wrapper {

	@include mq($from: tablet) {
		height: auto;
		margin: 0 20px;
	}

	@include mq($from: desktop) {
		margin: 0 40px;
	}

	@include mq($from: wide) {
		margin: 0 60px;
	}
}


/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

/************* TITLES *************/
.title-lev1 {
	text-align: center;
	font-family: $font-title;
	color: $black;
	@include font-size(32);

	/* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	@include mq($from: tablet) {
		@include font-size(32);
	}
	@include mq($from: desktop) {
		@include font-size(40);
	}
	@include mq($from: wide) {
		@include font-size(48);
	}
}

.title-lev2 {
	text-align: center;
	text-transform: uppercase;
	font-family: $font-bold;
	color: $color-1;
	@include font-size(12);
	letter-spacing: 0.2em;
	height: 24px;
	line-height: 24px;

	/* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	@include mq($from: tablet) {
		@include font-size(12);
		letter-spacing: 0.22em;
		height: 40px;
		line-height: 40px;
	}
	@include mq($from: desktop) {
		@include font-size(12);
		letter-spacing: 0.22em;
		height: 60px;
		line-height: 60px;
	}
	@include mq($from: wide) {
		@include font-size(12);
		letter-spacing: 0.22em;
		height: 70px;
		line-height: 70px;
	}
}

.name-gallery {
	font-family: $font-title !important;
	margin-bottom: 0 !important;
	font-size: 15px;
	color: $color-1;
}

.date-gallery {
	font-family: $font-bold !important;
	text-transform: uppercase;
	color: $color-1;
	@include font-size(12);
	letter-spacing: 0.2em;
	height: 24px;
	line-height: 24px;
	
	@include mq($from: mobileLandscape) {
		line-height: 40px;
	}

	@include mq($from: tablet) {
		line-height: 40px;
	
	}

	@include mq($from: desktop) {
		line-height: 40px;
	}

	@include mq($from: wide) {
		line-height: 40px;
	
	}
}

/********** EDITOR TEXT **********/
a {
	color: $color-link;

	.no-touchevents & {
		&:hover {
			color: $color-link-hover;
		}
	}
}

ul.main-listing {

	li {
		display: block;
		margin-bottom: 1.5em;

		font-family: $font-base;
		line-height: 22px;
		font-size: 15px;

		padding-left: 30px;
		position: relative;

		&:before {
			display: block;
			content: "";
			width: 16px;
			height: 1px;
			// width: 6px;
			// height: 6px;
			background-color: rgba($color-1, 0.7);

			position: absolute;
			top: 14px;
			left: 6px;
		}
	}
}

.color-1 { color: $color-1; }
.color-2 { color: $color-2; }
.color-3 { color: $color-3; }

/********** EDITOR TEXT **********/
.editor-text {
	color: $color-text;

	p {
		line-height: 1.5;
		margin-bottom: 1.5em;
	}

	a {
		color: $color-link;
		display: inline-block;
		border-bottom: 1px solid $color-link;

		transition:
			color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		.no-touchevents & {
			&:hover, &:focus {
				color: lighten($color-link, 10%);
				border-color: lighten($color-link, 10%);
			}
		}
	}

	h1 {
		margin-bottom: 1.5em;
		font-family: $font-base;
		text-transform: uppercase;
		@include font-size(20);
		font-weight: 700;
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-base;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}
}

/* 
##          #######      ###     ########   ########   ########   
##         ##     ##    ## ##    ##     ##  ##         ##     ##  
##         ##     ##   ##   ##   ##     ##  ##         ##     ##  
##         ##     ##  ##     ##  ##     ##  ######     ########   
##         ##     ##  #########  ##     ##  ##         ##   ##    
##         ##     ##  ##     ##  ##     ##  ##         ##    ##   
########    #######   ##     ##  ########   ########   ##     ##  
*/
.loader {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: #fff;
	width: 100%;
	height: 100%;

	svg {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
 
	.rect {
		fill: none;
		stroke-width: 8px;
		stroke: $black;
		// stroke: $color-1<;
		stroke-dasharray: 40;
		stroke-dashoffset: 50%;
 
		animation: movedash 1.5s forwards 0s infinite;
	}

	@keyframes movedash {
		to {
			stroke-dashoffset: 250%;
		}
	}
}


/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
.header-top {
	width: 100%;
	height: 80px;
	position: fixed;
	top: 0;
	background-color: $color-4;
	z-index: 1043;
	border-bottom: 1px solid rgba($black, 0.1);

	.home & {
		.ontop & {
			@include mq($from: tablet) {
				background-color: transparent;

				border-bottom: 1px solid $white;
				border-bottom: 1px solid rgba($white, 0.3);
			}
		}
	}
}

.logo {
	display: block;
	z-index: 115;

	font-family: $font-icon;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	width: 52px;
	left: 52px;
	position: absolute;
	top: 15px;
	left: 6px;

	@include mq($from: tablet) {
		left: 50%;
		margin-left: -26px;
	}

	span {
		display: block;
		text-indent: -9999px;
	}

	&:before {
		content: "\e901";
		color: $black;
		@include font-size(52);

		.home & {
			.ontop & {
				@include mq($from: tablet) {
					color: $white;
				}
			}
		}
	}
}


/* 
##    ##      ###     ##     ##  
###   ##     ## ##    ##     ##  
####  ##    ##   ##   ##     ##  
## ## ##   ##     ##  ##     ##  
##  ####   #########   ##   ##   
##   ###   ##     ##    ## ##    
##    ##   ##     ##     ###     
*/
.navigation {
	z-index: 1044;

	@include mq($until: tablet) {
		display: block;
		transition: right 0.2s ease-in-out;
		position: fixed;
		top: 0;
		bottom: 0;
		right: -100%;
		width: 100%;
		background-color: $white;
		background-color: rgba($white, 0.9);

		&.open {
			right: 0;
		}
	}

	@include mq($from: tablet) {
		position: fixed;
		top: 0;
		left: 0;
		height: 0;
		width: 100%;
	}
}

.menu {
	clear: both;
	@include clearfix;

	@include mq($until: tablet) {
		text-align: center;
	}

	@include mq($from: tablet) {
		position: absolute;
		height: 80px;
		width: auto;
		top: 0;
	}

	.touchevents & {
		@include mq(mobileLandscape, tablet) {
			position: absolute;
			padding: 30px 0 0 0;
		}
	}

	&.menu--left {
		
		@include mq($until: tablet) {
			padding: 80px 0 0 0;
		}

		.touchevents & {
			@include mq(mobileLandscape, tablet) {
				padding: 30px 0 0 0;
			}
		}
		
		@include mq($from: tablet) {
			text-align: left;
			left: 0;
			margin-left: 20px;
		}

		@include mq($from: desktop) {
			margin-left: 40px;
		}

		@include mq($from: wide) {
			margin-left: 60px;
		}

		.touchevents & {
			@include mq(mobileLandscape, tablet) {
				position: absolute;
				right: 56%;
			}
		}
	}

	&.menu--right {
		
		.touchevents & {
			@include mq(mobileLandscape, tablet) {
				position: absolute;
				left: 50%;
			}
		}
		
		@include mq($from: tablet) {
			text-align: right;
			right: 0;
			margin-right: 15px;
		}

		@include mq($from: desktop) {
			margin-right: 30px;
		}

		@include mq($from: wide) {
			margin-right: 50px;
		}
	}

	li {
		@include mq($until: tablet) {
			// margin: 1.3em auto;
			height: 12vh;
		}

		@include mq($from: tablet) {
			display: inline-block;
			line-height: 80px;
			margin: 0 4px;
		}

		@include mq($from: desktop) {
			display: inline-block;
			margin: 0 8px;
		}
	}

	a {
		display: inline-block;
		position: relative;
		padding: 0 20px;
		border: 1px solid transparent;
		margin: 4px auto;

		text-align: center;
		transition: color 0.3s;

		text-transform: uppercase;
		@include font-size(20);
		font-weight: 700;
		color: $black;
		line-height: 1.6em;

		transition: color 0.2s ease-in-out;

		@include mq($from: tablet) {
			border: none;
			padding: 0 6px;
			margin: 0;
			width: auto;
			color: $black;
			@include font-size(12);
			letter-spacing: 0.15em;
			line-height: 1.6em;
		}

		@include mq($from: desktop) {
			padding: 0 8px;
			@include font-size(13);
		}

		@include mq($from: wide) {
			padding: 0 10px;
			@include font-size(14);
		}

		.home & {
			.ontop & {
				@include mq($from: tablet) {
					color: $white;
				}
			}
		}

		&:before, &:after {
			content: '';
			position: absolute;
			background: $color-1;
			transition: transform 0.3s;
			transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);
			left: 0;
			width: 100%;
			height: 1px;
			transform: scale3d(0, 0.1, 1);

			.ontop & {
				@include mq($from: tablet) {
					background: lighten($color-1, 10%);
				}
			}
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}

		span {
			position: absolute;
			display: block;
			min-height: 100%;
			width: 100%;
			top: 0;
			left: 0;

			&:before, &:after {
				content: '';
				position: absolute;
				background: $color-1;
				transition: transform 0.3s;
				transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);
				top: 0;
				width: 1px;
				height: 100%;
				transform: scale3d(0.1, 0, 1);

				.ontop & {
					@include mq($from: tablet) {
						background: lighten($color-1, 10%);
					}
				}
			}

			&:before {
				left: 0;
			}

			&:after {
				right: 0;
			}
		}

		.no-touchevents & {
			&:hover, &.current {
				color: $color-1;

				&:before, &:after {
					transform: scale3d(1, 1, 1);
				}

				span:before, span:after {
					transform: scale3d(1, 1, 1);
				}
			}
		}

		.no-touchevents.ontop & {
			&:hover, &.current {
				@include mq($from: tablet) {
					color: lighten($color-1, 14%);
				}
			}
		}

		.touchevents & {
			&.current {
				color: $color-1;

				&:before, &:after {
					transform: scale3d(1, 1, 1);
				}

				span:before, span:after {
					transform: scale3d(1, 1, 1);
				}
			}
		}

		.touchevents.ontop & {
			&:hover, &:focus, &.current {
				@include mq($from: tablet) {
					color: lighten($color-1, 14%);
				}
			}
		}
	}

	&.menu--left li {

		&:first-of-type {
			margin-left: 0;
		}

		@include mq($until: tablet) {
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&.menu--right li {

		@include mq($from: tablet) {
			margin: 0 6px;
		}

		@include mq($from: desktop) {
			margin: 0 10px;
		}

		&:last-of-type {
			margin-left: 0;
		}

		a {

			@include mq($from: tablet) {
				letter-spacing: 0.2em;
			}
		}
	}
}

/* 
########    #######    ######     ######    ##         ########   
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##         ##         ##         ##         
   ##      ##     ##  ##   ####  ##   ####  ##         ######     
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##       #######    ######     ######    ########   ########   
*/
.nav-toggle {
	position: fixed;
	width: 70px;
	height: 80px;
	top: 0;
	right: 0;
	z-index: 1045;

	@include mq($from: tablet) {
		display: none;
	}
}

@mixin line {
	display: inline-block;
	width: 18px;
	height: 3px;
	background: $color-1;
	transition: 0.2s ease-in-out;
}

.lines-button {
	position: absolute;
	height: auto;
	width: auto;
	right: 24px;
	top: 26px;
	z-index: 101;
	display: inline-block;
	transition: .3s;
	cursor: pointer;
	user-select: none;
	padding: 0;

	.no-touchevents .nav-toggle:hover &:not(.close) .lines, 
	.no-touchevents .nav-toggle:hover & .lines:before, 
	.no-touchevents .nav-toggle:hover & .lines:after {
		background: rgba($color-1, 0.8);
	}

	@include mq($from: tablet) {
		display: none;
	}
}

.lines {
	@include line;
	position: relative;

	&:before, &:after {
		@include line;
		position: absolute;
		left: 0;
		content: '';
		transform-origin: center center;
	}

	&:before { top: 6px; }
	&:after { top: -6px; }
}

.lines-button.close .lines {
	background: transparent;

	&:before, &:after {
		transform-origin: 50% 50%;
		top: 0;
		width: 20px;
	}
	&:before {
		transform: rotate3d(0,0,1, 45deg); 
	}
	&:after {
		transform: rotate3d(0,0,1, -45deg); 
	}
}


/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
*/

/********** HOME SECTIONS **********/
.home-section {

	.touchevents & {
		@include mq(mobileLandscape, tablet) {
			height: auto;
		}
	}
}


/**** HEADER ****/
.section--header {
	height: 100vh;
	background: transparent url('#{$image-path}bg-home.png') center center no-repeat;
	background-size: cover;

	position: relative;

	.touchevents & {
		@include mq(mobileLandscape, tablet) {
			height: 440px;
		}
	}
}

.header-content {
	position: absolute;
	left: 50%;
	margin-left: -160px;
	top: 50%;
    transform: translateY(-50%);

	@include mq($from: mobileLandscape) {
		margin-left: -150px;

		.touchevents & {
			top: 28%;
		}
	}

	@include mq($from: tablet) {
		margin-left: -215px;
	}

	@include mq($from: desktop) {
		margin-left: -250px;
	}

	.logo-big {
		background: transparent url('#{$image-path}logo-full.png') 0 0 no-repeat;
		width: 250px;
		height: 135px;
		background-size: 250px 135px;

		@include retina {
			background-image: url('#{$image-path}logo-full@2x.png');
		}

		@include mq($from: mobileLandscape) {
			width: 300px;
			height: 162px;
			background-size: 300px 162px;
		}

		@include mq($from: tablet) {
			width: 430px;
			height: 232px;
			background-size: 430px 232px;
		}

		@include mq($from: desktop) {
			width: 500px;
			height: 270px;
			background-size: 500px 270px;
		}
	}

	.baseline {
		position: relative;
		width: 100%;
		height: 44px;
		line-height: 44px;
		margin-top: 20px;
		border-style: solid;
		border-color: $color-1;
		border-color: rgba($color-1, 0.3);
		border-width: 1px 0 1px 0;
		color: $color-1;
		text-transform: uppercase;
		text-align: center;
		@include font-size(11);
		letter-spacing: 0.2em;

		&:after {
			display: block;
			content: "";
			position: absolute;
			top: 100%;
			top: calc(100% + 1px);
			left: 50%;
			width: 1px;
			height: 96%;
			background-color: $white;
			background-color: rgba($white, 0.3);
		}

		@include mq($from: mobileLandscape) {
			height: 50px;
			line-height: 50px;
			letter-spacing: 0.25em;
		}

		@include mq($from: tablet) {
			height: 64px;
			line-height: 64px;
			@include font-size(12);
			letter-spacing: 0.45em;
			margin-top: 30px;
		}

		@include mq($from: desktop) {
			height: 80px;
			line-height: 80px;
			@include font-size(13);
			letter-spacing: 0.55em;
			margin-top: 40px;
		}

		@include mq($from: wide) {
			margin-top: 56px;
		}
	}
}

/**** MODE, PUB, BEAUTÉ (galeries) ****/
.section--content {
	background-color: $white;
	border-color: rgba($color-border, 0.1);

	@include mq($until: tablet) {
		height: auto;
	}

	.wrapper {
		margin: 0 20px;
		padding-bottom: 20px;

		@include mq($from: desktop) {
			margin: 0 40px;
			padding-bottom: 40px;
		}

		@include mq($from: wide) {
			margin: 0 60px;
			padding-bottom: 60px;
		}
	}

	.wrapper-pages {
		overflow: hidden;
		max-width: 100%;
		margin: 0 20px;

		@include mq($from: desktop) {
			margin: 0 40px;
		}

		@include mq($from: wide) {
			margin: 0 60px;
		}

		@include mq($from: xwide) {
			margin: 0 auto;
			width: 1300px;
		}
	}

	.wrapper-home{
		margin: 30px 20px 0;
		padding-bottom: 20px;

		@include mq($from: desktop) {
			margin: 30px 40px 0;
			padding-bottom: 40px;
		}

		@include mq($from: wide) {
			margin: 60px 60px 0;
			padding-bottom: 60px;
		}
	}
}

.album-slider {
	position: relative;
}

.album-item {

	@include mq($until: tablet) {
		height: 400px;
	}

	position: relative;
	width: 100%;
	// height: 100vh;
	// height: calc(100vh - 120px);
	height: 600px;

	background-size: cover;
	background-position: top center;

	@include mq($from: desktop) {
		// height: calc(100vh - 160px);
		height: 680px;
	}
	@include mq($from: wide) {
		// height: calc(100vh - 200px);
		height: 760px;
	}
}

.album--detail {
	position: absolute;
	width: 140px;
	height: 140px;
	left: 50%;
	margin-left: -70px;
	top: 50%;
	margin-top: -70px;
	padding: 0 20px;

	background-color: rgba($white, 0.6);
	border: 1px solid $color-1;
	border-color: rgba($color-1, 0.8);

	transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

	@include font-size(12);
	text-align: center;
	color: $white;
	text-transform: uppercase;
	letter-spacing: 0.25em;
	line-height: 1.6em;

	@include mq($from: tablet) {
		width: 200px;
		height: 200px;
		margin-left: -100px;
		margin-top: -100px;
		padding: 30px;

		@include font-size(13);
		letter-spacing: 0.3em;
		line-height: 1.7em;
	}

	@include mq($from: desktop) {
		width: 260px;
		height: 260px;
		margin-left: -130px;
		margin-top: -130px;
		padding: 44px;

		@include font-size(14);
		letter-spacing: 0.4em;
		line-height: 1.8em;
	}

	@include mq($from: wide) {
		width: 300px;
		height: 300px;
		margin-left: -150px;
		margin-top: -150px;
		padding: 50px;

		@include font-size(15);
		letter-spacing: 0.5em;
	}
}

.album--title {
	position: relative;

	font-family: $font-bold;
	color: $black;
	
	&:after {
		display: block;
		content: "";
		width: 12px;
		height: 1px;
		background-color: $black;
		position: absolute;
		bottom: -8px;
		left: 50%;
		margin-left: -6px;

		@include mq($from: tablet) {
			width: 16px;
			bottom: -10px;
			margin-left: -8px;
		}
		@include mq($from: desktop) {
			width: 18px;
			bottom: -12px;
			margin-left: -9px;
		}
		@include mq($from: wide) {
			width: 22px;
			margin-left: -11px;
		}
	}
}

.album--date {
	color: $black;
	padding-top: 3px;

	@include mq($from: tablet) {
		padding-top: 5px;
	}
	@include mq($from: desktop) {
		padding-top: 8px;
	}
}

.album--link {
	display: inline-block;
	margin-top: 1em;
	
	color: $white;
	@include font-size(10);
	letter-spacing: 0.1em;
	line-height: 1.3em;

	padding: 2px 8px;
	border: 1px solid $white;
	border-color: rgba($white, 0.6);
	background-color: $color-1;
	background-color: rgba($color-1, 0.9);

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	@include mq($from: tablet) {
		margin-top: 1.3em;
		@include font-size(11);
		letter-spacing: 0.15em;
		padding: 3px 8px;
	}

	@include mq($from: desktop) {
		margin-top: 1.6em;
	}

	@include mq($from: wide) {
		margin-top: 1.8em;
		@include font-size(12);
		padding: 4px 10px;
	}

	.no-touchevents & {
		&:hover, &:focus {
			background-color: $color-1;
			color: $white;
		}
	}
}

.album--detail {
	.no-touchevents & {
		&:hover {
			cursor: pointer;
			transform: scale(1.2);
			background-color: rgba($black, 0.4);
			// border-color: rgba($color-1, 0.3);

			.album--title {
				color: $white;

				&:after {
					background-color: $white;
				}
			}

			.album--date {
				color: $white;
			}
		}
	}

	.touchevents & {
		&:active {
			background-color: rgba(#000, 0.4);
			border-color: rgba($color-1, 0.3);
			transform: scale(1.2);
		}
	}
}

/* Styling Next and Prev buttons */
.owl-theme .owl-controls {
	height: 40px;
	width: 100%;
	width: calc(100% + 20px);
	position: absolute;
	top: 50%;
	margin-top: -20px;
	left: -10px;

	@include mq($from: desktop) {
		height: 50px;
		width: calc(100% + 50px);
		left: -25px;
	}

	@include mq($from: wide) {
		height: 60px;
		width: calc(100% + 60px);
		left: -30px;
	}

	.owl-nav {
		height: 100%;

		.owl-prev, .owl-next {
			display: block;
			height: 40px;
			width: 40px;
			position: absolute;

			background-color: $white;
			background-color: rgba($white, 0.7);

			border: 1px solid $color-border;
			border-color: rgba($color-border, 0.5);

			transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

			@include mq($from: desktop) {
				height: 50px;
				width: 50px;
			}
			@include mq($from: wide) {
				height: 60px;
				width: 60px;
			}

			&:before {
				font-family: 'emilie' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

			    /* Better Font Rendering =========== */
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;

			    display: block;
			    color: $color-arrow;
			    color: rgba($color-arrow, 0.85);
			    @include font-size(22);
			    
			    position: absolute;
			    width: 100%;
			    height: 100%;
			    line-height: 1.7em;
			    top: 0;

			    @include mq($from: desktop) {
					@include font-size(29);
				}
				@include mq($from: wide) {
					@include font-size(35);
				}
			}
		}

		.owl-prev {
			left: 0;

			&:before {
				content: "\e902";
				right: 0;
				transition: right 0.3s ease-in-out, color 0.3s ease-in-out;
			}

			.no-touchevents & {
				&:hover, &:focus {
					border-color: $color-1;
					background-color: rgba($white, 0.8);

					&:before {
						opacity: 1;
						color: $color-1;
						right: 16px;

						@include mq($from: desktop) {
							right: 20px;
						}
						@include mq($from: wide) {
							right: 28px;
						}
					}
				}
			}
		}

		.owl-next {
			right: 0;

			&:before {
				content: "\e903";
				left: 0;
				transition: left 0.2s ease-in-out, color 0.3s ease-in-out;
			}

			.no-touchevents & {
				&:hover, &:focus {
					border-color: $color-1;
					background-color: rgba($white, 0.8);

					&:before {
						opacity: 1;
						color: $color-1;
						left: 16px;

						@include mq($from: desktop) {
							left: 20px;
						}
						@include mq($from: wide) {
							left: 28px;
						}
					}
				}
			}
		}
	}
}


/* Slider */
.owl-carousel--detail {

	.owl-stage-outer,
	.owl-stage {
		@include mq($until: tablet) {
			height: 100vh;
			max-height: calc(100vh - 80px);
		}

		max-height: calc(100vh - 135px);
	}

	.owl-item {

		.item {
			@include mq($until: tablet) {
				height: calc(100vh - 80px);
			}

			height: 100vh;
			height: calc(100vh - 135px);

			&.item-intro {
				background-color: $color-1;
				// background-color: rgba($color-1, 0.95);
				text-align: center;
				width: 320px;
				max-width: 100%;

				@include mq($from: tablet) {
					width: 680px;
				}

				@include mq($from: desktop) {
					width: 940px;
				}

				@include mq($from: wide) {
					width: 1180px;
				}
			}

			img {
				margin: 0;
				width: auto;
				height: 100%;
				max-height: 100%;
			}
		}
	}
}

/* Nav */
.owl-carousel--detail.owl-theme .owl-controls {
	width: 100%;
	width: calc(100% - 20px);
	left: 10px;

	@include mq($from: desktop) {
		width: calc(100% - 40px);
		left: 20px;
	}

	@include mq($from: wide) {
		width: calc(100% - 60px);
		left: 30px;
	}
}

.md-close {
	position: absolute;
	top: 12px;
	left: 10px;
	z-index: 10;
	-webkit-appearance: none;
	border: none;
	background-color: transparent;
	width: 30px;
	height: 30px;

	@include mq($from: desktop) {
		width: 38px;
		height: 38px;
		top: 18px;
		left: 16px;
	}

	@include mq($from: wide) {
		width: 44px;
		height: 44px;
		top: 22px;
		left: 20px;
	}

	span {
		display: block;

		font-family: 'emilie' !important;
	    speak: none;
	    font-style: normal;
	    font-weight: normal;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;

	    /* Better Font Rendering =========== */
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;

		&:before {
			content: "\e904";
			color: $color-border;
			color: rgba($color-border, 0.8);
			@include font-size(28);

			transition: transform 0.2s ease-in-out;

			@include mq($from: desktop) {
				@include font-size(36);
			}

			@include mq($from: wide) {
				@include font-size(42);
			}
		}
	}

	.no-touchevents & {
		&:hover {
			span:before {
				transform: rotate(180deg);
			}
		}
	}
}

/***** ALBUM VIDEO *****/
.cd-section--video {
	// display: block;
	// width: auto;
	// height: 100vh;
	// position: relative;
	// z-index: -100;
}

.fade-in-video {
	opacity: 0;
	transition: opacity 0.8s linear;

	&.is-playing {
		opacity: 1;
	}
}

video#bgvid {
	min-width: 100%;
	min-height: 100%;
}


/**** PRESSE, ÉMILIE CHOUKRANE, CONTACT ****/
.specialistes-studio, .formules-studio, .equipe-studio, .listening-link-inner--content, .section--tarif {
	 opacity: 0;
}

.presse--container,
.equipe--container,
.specialistes--container,
.tarif--container,
.mariage--container {
	@include mq($until: tablet) {
		height: 400px;
	}

	position: relative;
	width: 100%;
	height: 600px;

	background-size: cover;
	background-position: top center;

	@include mq($from: desktop) {
		height: 680px;
	}
	@include mq($from: wide) {
		height: 760px;
	}
}

.contact--container-top {
	overflow: hidden;
	@include mq($until: tablet) {
		height: 700px;
	}

	position: relative;
	width: 100%;
	height: 600px;

	background-size: cover;
	background-position: top center;

	@include mq($from: desktop) {
		height: 680px;
	}
	@include mq($from: wide) {
		height: 630px;
	}
}

/**** PRESSE ****/
.presse-content {
	@include clearfix;
	height: 100%;
	background-color: $color-3;
}

.press-slider {
	@include center();
}

.press-item {
	border: 1px solid #c1c1c1;
	background-color: $white;
	padding: 20px;
	width: 100%;

	@include mq($from: desktop) {
		padding: 30px;
	}

	@include mq($from: wide) {
		padding: 35px;
	}

	a {
		display: block;
		height: 100%;
		width: 100%;
		opacity: 1;

		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

		.no-touchevents & {
			&:hover, &:focus {
				transform: scale(1.08);
				opacity: 0.9;
			}
		}
	}
}

/**** INNER CONTENTS (PRESSE + ÉMILIE) ****/
.section--content .inner--content {
	width: 220px;
	margin: 0 auto;
	padding-top: 60px;

	@include mq($from: mobileLandscape) {
		float: right;
		padding: 0;
		margin: 60px 30px 0 0;
	}

	@include mq($from: tablet) {
		width: 360px;
		margin: 100px 60px 0 0;
	}

	@include mq($from: desktop) {
		width: 320px;
		margin: 60px 60px 0 0;
	}

	@include mq($from: wide) {
		width: 400px;
		margin: 80px 80px 0 0;
	}

	@include mq($from: xwide) {
		width: 480px;
		margin: 100px 80px 0 0;
	}
}

/**** ÉMILIE CHOUKRANE ****/
.section--content--banner {

	.wrapper-home {
		// overflow: hidden;
		max-width: 100%;
		padding-top: 60px;
		
		@include mq($from: xwide) {
			margin: 0 auto;
			width: 1300px;
		}

		&.wrapper-home--mariage {
			padding-top: 0;
		}
	}

	.title-lev2 {
		height: 40px;
		line-height: 40px;
		margin-bottom: 40px;
		@include mq($until: tablet) {
			height: 40px;
			line-height: 40px;
		}
		@include mq($from: desktop) {
			height: 40px;
			line-height: 40px;
		}
		@include mq($from: wide) {
			height: 40px;
			line-height: 40px;
		}
	}

	.specialistes {
		overflow: hidden;
		background-color: $color-4;

		&.specialistes--lighten {
			background-color: lighten($color-4, 4%);
			// background-color: transparent;
		}

		.specialistes--container {
			position: relative;
			width: 100%;
			height: 260px;
			background: url('#{$image-path}specialistes-alt.jpg') 50% no-repeat;
			background-size: cover;
			background-position: top center;

			@include hidpi {
	    		background: url('#{$image-path}specialistes-alt@2x.jpg');
	    		background-size: cover;
				background-position: top center;
	    	}		

			@include mq($until: tablet) {
				height: 200px;
			}
			@include mq($from: desktop) {
				height: 230px;
			}
			@include mq($from: wide) {
				height: 260px;
			}

			&.mariage--container {
				background-image: url('#{$image-path}mariage.jpg');
				background-size: cover;

				@include hidpi {
		    		background-image: url('#{$image-path}mariage@2x.jpg');
		    		background-size: cover;
		    	}
			}
		}



		.block-inner--content {
			width: 955px;
			max-width: 100%;
			margin: 0 auto;
			padding: 60px 0;
			overflow: hidden;

			&:before, &:after {
				display: none;
			}

			@include mq($until: desktop) {
				padding: 20px;
			}

			.inner-text {
				font-family: $font-base;
				font-size: 15px;
				line-height: 22px;
			}

		}

		.col-1-2 {
			@include mq($from: mobileLandscape) {
				width: 100%;
			}

			@include mq($from: desktop) {
				width: 50%;
			}
		}
	}
}

.block-inner--content {
		position: relative;
		height: 100%;
		padding: 20px;
		background-color: $color-4;

		&.block-inner--content--lighten {
			// background-color: lighten($color-4, 4%);
			background-color: transparent;
			// padding: 20px !important;
		}
		
		.inner {
			position: absolute;
			padding: 40px;
			top: 20px; left: 20px; right: 20px; bottom: 20px;
			border: 1px solid rgba($color-1, .3);

			&:before,
			&:after {
				display: block;
				width: 10px;
				height: 10px;
				content: "";
				position: absolute;
				bottom: -1px;
				z-index: 10;
				border: 1px solid $color-1;
			}

			&:before {
				left: -1px;
				border-right: none;
				border-top: none;
			}

			&:after {
				right: -1px;
				border-left: none;
				border-top: none;
			}
		}

		.title-lev2 {
			text-align: left;
			@include mq($from: mobileLandscape) {
				line-height: 22px;
				height: 40px;
			}

			@include mq($from: tablet) {
				line-height: 22px;
				height: 40px;
			}

			@include mq($from: desktop) {
				line-height: 22px;
				height: 40px;
			}

			@include mq($from: wide) {
				line-height: 22px;
				height: 40px;
			}
		}

		p {
			font-family: $font-base;
			line-height: 22px;
			font-size: 15px;

			&:nth-child(3) {
				margin-bottom: 20px;
			}
		}

		strong {
			font-family: $font-bold;
		}

		a {
			position: relative;
			display: inline-block;
			font-family: $font-title;
			font-size: 16px;
			border: 3px solid transparent;

			&:after {
				content:"";
				position: absolute; bottom: -3px; 
				left: 9px;
				right: 9px;
				background-color: rgba($color-1, .7);
				height: 1px;
				transition: 
			        transform 0.8s cubic-bezier(1,0,.37,1) 0.2s,
			        right 0.2s cubic-bezier(.04,.48,0,1) 0.6s,
			        left 0.4s cubic-bezier(.04,.48,0,1) 0.6s;
      			transform-origin: left;
			}

			.text {
				transform: translate3d(0,.7em,0);
			    display: block;
			    transition: transform 0.4s cubic-bezier(.2,0,0,1) 0.4s;
			    padding: .5em;
			}

			.line {
			    position: absolute;
			    background: $color-1;

			    &.-right,
			    &.-left {
			      width: 1px;
			      bottom: -3px;
			      top: -3px;
			      transform: scale3d(1,0,1);
			    }  

			    &.-top,
			    &.-bottom {
			      height: 1px;
			      left: -3px;
			      right: -3px;
			      transform: scale3d(0,1,1);
			    }

			    &.-right {
			      right: -3px;
			      transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.23s;
			      transform-origin: top;
			    }

			    &.-top {
			      top: -3px;
			      transition: transform 0.08s linear 0.43s;
			      transform-origin: left;
			    }

			    &.-left {
			      left: -3px;
			      transition: transform 0.08s linear 0.51s;
			      transform-origin: bottom;
			    }

			    &.-bottom {
			      bottom: -3px;
			      transition: transform 0.3s cubic-bezier(1,0,.65,1.01);
			      transform-origin: right;
			    }  
			  }

			  &:hover,
			  &:active {
			    .text {
			      transform: translate3d(0,0,0);
			      transition: transform 0.6s cubic-bezier(.2,0,0,1) 0.4s;
			    }

			    &:after {
			      transform: scale3d(0,1,1);
			      right: -3px;
			      left: -3px;
			      transform-origin: right;
			      transition: 
			        transform 0.2s cubic-bezier(1,0,.65,1.01) 0.17s,
			        right 0.2s cubic-bezier(1,0,.65,1.01),
			        left 0s 0.3s;
			    }

			    .line {
			      transform: scale3d(1,1,1);

			      &.-right {
			        transition: transform 0.1s cubic-bezier(1,0,.65,1.01) 0.2s;
			        transform-origin: bottom;
			      }

			      &.-top {
			        transition: transform 0.08s linear 0.4s;
			        transform-origin: right;
			      }

			      &.-left {
			        transition: transform 0.08s linear 0.48s;
			        transform-origin: top;
			      }

			      &.-bottom {
			        transition: transform 0.5s cubic-bezier(0,.53,.29,1) 0.56s;
			        transform-origin: left;
			      }
			    }

			  }
		}
		&:before {
			content: "";
			position: absolute;
			z-index: 1;
			top:20px;
			left:20px;
			width:10px;
			height: 10px;
			border: 1px solid $color-1;
			border-right: 0;
			border-bottom: 0;
		}
		&:after {
			content: "";
			position: absolute;
			top:20px;
			right:20px;
			width:10px;
			height: 10px;
			border: 1px solid $color-1;
			border-left: 0;
			border-bottom: 0;
		}
	}

.section--formules{

	.title-lev1 {
		margin-bottom: 40px;
	}

.formules {
	width: 925px;
	height:360px;
	max-width: 100%;
	margin: 0 auto;

	.formules--container--left, .formules--container--right {
		width: 100%;
	    height: 100%;
	    float: left;

		@include mq($from: desktop) {
			width: 50%;
			margin-bottom: 0;
			padding-left: 50px;
		}
	}

	.formules--container--left {
		background: transparent url('#{$image-path}formules.jpg') 50% no-repeat;
    	background-size: cover;

    	@include hidpi {
    		background: url('#{$image-path}formules@2x.jpg');
    		background-size: cover;
    	}
	}

	.formules--container--right {
		margin-bottom: 30px;
    	
    	@include mq($from: desktop) {
			margin-bottom: 0;
		}
	}
}
}

.section--equipe {
	margin: 150px 0 0;

	@include mq($until: desktop) {
		margin: 90px 0 0;
	}

	.wrapper-pages {
		
		 margin: 30px 20px;

		 @include mq($from: mobileLandscape) {
			margin: 100px 20px;
		}
		@include mq($from: desktop) {
			margin: 100px 20px;
		}
		@include mq($from: wide) {
			margin: 100px 60px;
		}
	}
	
	.equipe-studio {
		overflow: hidden;
		width: 1040px;
		max-width: 100%;
		margin: 0 auto;
		
	.equipe--container--left, .equipe--container--right {
		width: 100%;
	    height: 100%;
	    float: left;
		margin-bottom: 30px;

		@include mq($until: desktop) {
			margin-bottom: 0;
		}
	}

	.equipe--container--left {
		height: 600px;
		@include mq($from: mobileLandscape) {
			height: 600px;
		}
		@include mq($from: desktop) {
			width: 47%;
			margin-bottom: 0;
			display: block;
		}
	}

	.equipe--container--right {
		@include mq($from: desktop) {
			width: 53%;
			margin-bottom: 0;
		}
	}

	.equipe--container--left.visible {
		display: none !important;
		@include mq($from: mobileLandscape) {
			display: none !important;
		}
		@include mq($from: tablet) {
			display: none !important;
		}
		@include mq($from: desktop) {
			display: block !important;
		}
		@include mq($from: wide) {
			display: block !important;
		}
	}

	.equipe--container--left.hidden {
		display: block !important;
		@include mq($from: mobileLandscape) {
			display: block !important;
		}
		@include mq($from: tablet) {
			display: block !important;
		}
		@include mq($from: desktop) {
			display: none !important;
		}
		@include mq($from: wide) {
			display: none !important;
		}
	}

	.equipe--container--left.emilie {
		background: transparent url('#{$image-path}emilie.jpg') 50% no-repeat;
    	background-size: cover;

    	@include hidpi {
    		background-image: url('#{$image-path}emilie@2x.jpg');
    		background-size: cover;
    	}
	}

	.equipe--container--left.sui {
		background: transparent url('#{$image-path}sui.jpg') 50% no-repeat;
    	background-size: cover;

    	@include hidpi {
    		background-image: url('#{$image-path}sui@2x.jpg');
    		background-size: cover;
    	}
	}

	.equipe--container--left.karen {
		background: transparent url('#{$image-path}karen.jpg') 50% no-repeat;
    	background-size: cover;

    	@include hidpi {
    		background-image: url('#{$image-path}karen@2x.jpg');
    		background-size: cover;
    	}
	}

	.block-inner--content {
		position: relative;
		height: 100%;
		padding: 30px;
		background-color: $color-4;

		&:before, &:after {
			display: none;
		}
		
		@include mq($from: mobileLandscape) {
			padding: 30px;
		}

		@include mq($from: tablet) {
			padding: 70px;
		}


		.title-lev1 {
			color: $color-1;
			font-size: 32px;
			text-align: left;
			margin-bottom: 35px;

			@include mq($from: mobileLandscape) {
				line-height: 22px;
				height: 32px;
			}

			@include mq($from: tablet) {
				line-height: 22px;
				height: 32px;
			}

			@include mq($from: desktop) {
				line-height: 22px;
				height: 40px;
			}

			@include mq($from: wide) {
				line-height: 22px;
				height: 25px;
			}
		}

		p {
			font-family: $font-base;
			line-height: 1.6;
			font-size: 15px;
		}
	}
}
}

.section--listening {
	margin-top: 80px;

	&:before {
		content: "";
		position: fixed;
		bottom: 50px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: transparent url('#{$image-path}arrow-bottom.png') center center no-repeat;
	    width: 20px;
	    height: 100px;

	    @include mq($until: desktop) {
			display: none;
		}
	}

	@include mq($until: desktop) {
		margin-top: 40px;
	}

	.listening--container--left, .listening--container--right {
		width: 100%;
	    height: 100%;
	    float: left;
		
		@include mq($from: desktop) {
			width: 50%;
			margin-bottom: 0;
		}

		.media-inner--content{
			position: relative;
		  	overflow: hidden;
		
	  	&:after {
	  		content: "";
			position: absolute;
		  	left: 0; right: 0;
		  	top: 0; bottom: 0;
		  	background-color: rgba($white, .8);
		  	opacity: 0;
		  	-moz-transition: opacity .3s ease-in-out;
			  -webkit-transition: opacity .3s ease-in-out;
		  	transition: opacity .3s ease-in-out;
	  	}	

		 .text-media-inner--content {
		 	position: absolute;
		 	top: 50%; left: 0; right: 0;
		 	margin: 0 auto;
		 	transform: translateY(-50%);
		 	text-align: center;
		 	font-family: $font-bold;
		 	font-size: 20px;
		 	text-transform: uppercase;
		 	letter-spacing: .22em;
		 	color: $color-1;		 	opacity: 0;
		 	z-index: 10;
		 	-moz-transition: opacity .3s ease-in-out;
			  -webkit-transition: opacity .3s ease-in-out;
		 	transition: opacity .3s ease-in-out;
		 }

		 &:hover {
		  	&:after {
		  		content: "";
			  	opacity: 1;
		  	}

		  	.text-media-inner--content {
				opacity: 1;
		  	}
		 }
			
		img {
			width: 100%;
			max-width: 100%;  
			  -moz-transition: all 0.3s;
			  -webkit-transition: all 0.3s;
			  transition: all 0.3s;
			}
		}

		.listening-link-inner--content{
			display: block;
			margin-bottom: 80px;
			&:hover{
				.media-inner--content{
					img {
				  	  -moz-transform: scale(1.1);
					  -webkit-transform: scale(1.1);
					  transform: scale(1.1);
					}
				}
				.listening-block-inner--content {
					&:before {
						transform: translateY(50%) rotate(360deg) scale(1.3);
					}
				}
			}

			img {
				height: auto;
			}
			
			@include mq($from: mobileLandscape) {
				margin-bottom: 100px;
			}

			@include mq($from: tablet) {
				margin-bottom: 100px;
			}
			@include mq($from: desktop) {
				margin-bottom: 160px;
			}
		}

		.listening-block-inner--content {
			position: relative;
			border-bottom: 1px solid $color-4;
			padding: 25px 0 40px;
			&:before {
				content:"+";
				position: absolute;
				bottom: 0; left: 0; right: 0;
				margin: 0 auto;
				background-color: $color-1;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				color: white;
				font-family: $font-base;
				font-size: 25px;	
				text-align: center;
				transform: translateY(50%) rotate(0deg) scale(1);
				-moz-transition: all 0.5s;
			  -webkit-transition: all 0.5s;
			  transition: all 0.5s;
				
			}
		}

		.title-lev1 {
			font-size: 30px;
		}

		.title-lev2 {
			font-size: 10px;
			line-height: 13px;
			height: 20px;
		}
		
	}

	.listening--container--left {
		padding-right: 0;
		@include mq($from: mobileLandscape) {
			padding-right: 0;
		}

		@include mq($from: tablet) {
			padding-right: 0;
		}
		@include mq($from: desktop) {
			padding-right: 90px;
		}
	}

	.listening--container--right {
		position: relative;
		padding-top: 0;
		padding-left: 0;

		@include mq($from: mobileLandscape) {
			padding-left: 0;
			padding-top: 0;
		}

		@include mq($from: tablet) {
			padding-left: 0;
			padding-top: 0;
		}

		@include mq($from: desktop) {
			padding-left: 90px;
			padding-top: 80px;
		}
	}
}

.section--album{
	margin-top: 170px;

	@include mq($until: desktop) {
		margin-top: 140px;
	}

	.wrapper{
		overflow:hidden;
	}

	.gallery-isotope {
		margin-bottom: 40px !important;


		}

	.item {
		  position: relative;
		  float: left;
		  width: 50%;
		  padding: 25px;

		@include mq($until: desktop) {
			width: 100%;
		}
	}

	.row {
		margin: -25px;
	}

	img {
			width: 100%;
			height: auto;
		}

	.block-inner--content {
		
		.inner {
			position: initial;

			&:before {
				bottom:20px;
				left: 20px;
			}

			&:after {
				bottom: 20px;
				right: 20px;
			}
		}

		.title-lev1 {
			font-size: 16px;
			text-align: left;
			color: $color-1;
		}

		.text-section--album {
			color: $black;
			&:nth-child(3) {
				margin-bottom: 1em;
			}

			&:nth-child(4) {
				margin-bottom: 30px;
			}
		}
	 }
	
	.album--container--left, .album--container--right {
		width: 100%;
	    height: 100%;
	    float: left;
		
		@include mq($from: desktop) {
			width: 50%;
			margin-bottom: 0;
		}

	.col-1-2 {
		padding-top: 0;
		padding-right: 0;
		padding-left: 0;

			@include mq($from: mobileLandscape) {
				padding-right: 0;
			}

			@include mq($from: tablet) {
				padding-right: 25px;
			}
			
			&:last-child {
				padding-left: 0;
				padding-right: 0;
				@include mq($from: mobileLandscape) {
					padding-left: 0;
				}

				@include mq($from: tablet) {
					padding-left: 25px;
				}
			}
		}
	}

	.album--container--left {
		padding-right: 0;
		@include mq($from: mobileLandscape) {
			padding-right: 0;
		}

		@include mq($from: desktop) {
			padding-right: 25px;
		}
	}
	.album--container--right {
		padding-left: 0;
		@include mq($from: mobileLandscape) {
			padding-left: 0;
		}

		@include mq($from: desktop) {
			padding-left: 25px;
		}
	}
}

button.mfp-arrow {
	transform: translateY(-50%);
	width: 60px;
	height: 60px;
	background-color: rgba(170, 138, 85, .25);
	border: 1px solid white;
	margin: 0 !important;

	&:after {
		border: 0;
	}
}

.mfp-arrow-left {
    left: 100px;

     &:before {
		content:"";
		top: 50%; left: 0; right: 0;
		margin: 0 auto;
		background: url('#{$image-path}arrow-left.png') no-repeat;
		background-size: 100%;
	    border: 0;
	    width: 34px; height: 9px;
		opacity: 1;
		transform: translateY(-50%);
	}
}

.mfp-arrow-right {
    right: 100px;

    &:before {
		content:"";
		top: 50%; left: 0; right: 0;
		margin: 0 auto;
		background: url('#{$image-path}arrow-right.png') no-repeat;
		background-size: 100%;
	    border: 0;
	    width: 34px; height: 9px;
		opacity: 1;
		transform: translateY(-50%);
	}
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: pointer;
}

figcaption {
	display: none;
}

.link-image-album {
	position: relative;
	display: block;
	overflow: hidden;

	img {
		transition: transform .3s;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}

		&:before {
			transform: translateY(-50%);
			opacity: 1;
		}

		&:after {
				-webkit-transform: translateY(0);
		    -moz-transform: translateY(0);
		    -o-transform: translateY(0);
		    -ms-transform: translateY(0);
		    transform: translateY(0);
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 50%; left: 0; right: 0;
		margin: 0 auto;

		background: transparent url('#{$image-path}loupe.png') center center no-repeat;
		background-size: 40px;
	    width: 50px;
	    height: 50px;
	    opacity: 0;

		z-index: 1;
		transform-origin: center;
		transform: translateY(-50%);
		-moz-transition: all 0.5s;
		  -webkit-transition: all 0.5s;
		  transition: all 0.5s;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 1;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
		-webkit-transform: translateY(100%);
	    -moz-transform: translateY(100%);
	    -o-transform: translateY(100%);
	    -ms-transform: translateY(100%);
	    transform: translateY(100%);
	    background-color: rgba(black, .3);
	}
}


.inner--content.equipe--content {
	width: 280px;
	
	@include mq($from: mobileLandscape) {
		margin: 40px 20px 0 0;
	}

	@include mq($from: tablet) {
		width: 400px;
		margin: 80px 60px 0 0;
	}

	@include mq($from: desktop) {
		width: 440px;
		margin: 100px 80px 0 0;
	}

	@include mq($from: wide) {
		width: 460px;
		margin: 120px 100px 0 0;
	}

	@include mq($from: xwide) {
		width: 500px;
		margin: 120px 100px 0 0;
	}

	p {
		@include font-size(12);
		color: $white;
		color: rgba($white, 0.7);
		line-height: 1.4em;

		@include mq($from: tablet) {
			line-height: 1.5em;
			margin: 0 0 1.2em 0;
		}

		@include mq($from: desktop) {
			@include font-size(13);
			line-height: 1.6em;
			margin: 0 0 1.4em 0;
		}

		@include mq($from: wide) {
			line-height: 1.7em;
			margin: 0 0 1.8em 0;
		}
	}
}

.bottom-album {
	clear: both;
	text-align: center;

	.block-inner--content {
		margin-bottom: 80px;
		
		.inner {
			position: initial;

			&:before {
				bottom:20px;
				left: 20px;
				border-right: none;
				border-top: none;
			}

			&:after {
				bottom: 20px;
				right: 20px;
				border-left: none;
				border-top: none;
			}
		}

		p {
			position: relative;
			display: inline-block;
			margin: 0;
			padding: 3px 20px;
			font-family: $font-base;
			line-height: 22px;
			font-size: 15px;
			color: $color-1;
			border-right: 1px solid rgba($color-1, .3);

			@include mq($until: tablet) {
				display: block;
				margin-bottom: 10px;
				border-right: 0;
				&:after {
					display: none;
				}
			}

			&:last-child {
				border-right: 0;
				&:after {
					display: none;
				}
			}
			&:after {
				content: "";
				position: absolute;
				z-index: 1;
				top:50%;
				right:-1px;
				width:10px;
				height: 10px;
				border: 1px solid $color-1;
				border-left: 0;
				border-top: 0;
				border-bottom: 0;
				transform: translateY(-50%);
			}
		}
		strong {
			font-weight: 700;
		}
	}
}

.button-return-album {
	float: right;
	display: block;
	background-color: $color-1;
	color: white;
	font-family: $font-base;
	font-size: 13px;
	font-weight: 300;
	padding: 15px 30px;
	text-transform: uppercase;
	letter-spacing: 1px;
	transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

	&:hover {
		background-color: #edece8;
		color: $color-1;
	}
}

/**** CONTACT ****/
.contact--container--left,
.contact--container--right {
	width: 50%;
	height: 100%;
	float: left;
}

.contact--container--left {
	padding-right: 25px;
	
	background: url('#{$image-path}studio.jpg');
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	@include retina {
		background: url('#{$image-path}studio@2x.jpg');
		background-size: cover;
	}

	@include mq($until: tablet) {
		display: none;
	}
}

.contact--container--right {

	padding-left: 50px;

	@include mq($until: tablet) {
		width: 100%;
		padding: 0;
	}

	.contact-address,
	.contact-map {
		height: 60%;
	}

	.contact-address {
		background-color: $color-1;
		background-color: lighten($color-1, 5%);
		padding: 12px;

		.contact-address--detail {
			color: $white;
			text-align: center;

			border: 1px solid $white;
			border-color: rgba($white, 0.35);

			.contact-address--title {
				margin-bottom: 1.2em;

				font-family: $font-bold;
				text-transform: uppercase;
				letter-spacing: 0.25em;
				
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				&:after {
					display: block;
					content: "";
					height: 1px;
					width: 40px;
					background-color: $white;
					background-color: rgba($white, 0.6);
					margin: 0.5em auto 0 auto;
				}
			}

			p {
				margin-bottom: 0.6em;

				&:last-of-type {
					margin: 0;
				}

				a {
					color: $color-text-light;

					transition: color 0.2s ease-in-out;

					.no-touch &:hover,
					.no-touchevents &:hover,
					.no-touch &:focus,
					.no-touchevents &:focus,
					.touch &:active,
					.touchevents &:active {
						color: rgba($color-text-light, 0.8);
					}
				}
			}
		}
	}
}

.block-inner {
	position: relative;
	@include clearfix;
}
.form__message {
	display: inline-block;
	position: absolute;
	padding: 40px 20px;
	text-align: center;
	top: 50%;
	left: 50%;
	opacity: 0;
	z-index: 10;
	pointer-events:none;

	background-color: $color-1;

	@include font-size(16);
	color: $color-text-light;

	transform: translate(-50%, -50%);

	transition: transform 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out 0.3s;
	
	p {
		margin: 0;
	}
	
	.sending & {
		opacity: 1;
	}

}

.form__content {
	transition: transform 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out 0.3s;
	opacity: 1;

	.sending & {
		opacity: 0;
	}
}

.contact-content {
	background-color: $color-3;
	padding: 80px 0;

	@include mq($until: desktop) {
		padding: 40px;
	}
}

.nbre-appro {
	display: none;
	opacity: 0;
	position: absolute;
	bottom: 0; right: 0;
    background-color: $color-1;
    color: white;
    padding: 8px 12px;
    height: 38px;

    -webkit-transition: opacity 0.3s ease-out; 
	-moz-transition: opacity 0.3s ease-out; 
	-o-transition: opacity 0.3s ease-out; 
	transition: opacity 0.3s ease-out;

    &:after {
		content: "";
		position: absolute;
		top: 60%; right: 100%;

		border-style: solid;
	    border-width: 0 7px 15px;
	    border-color: transparent transparent $color-1;

	    transform: rotate(270deg) translateY(-50%);
	    transform-origin: top;
    }
}

// .form__inputfile {
// 	width: 0.1px;
// 	height: 0.1px;
// 	opacity: 0;
// 	overflow: hidden;
// 	position: absolute;
// 	z-index: -1;
// }

// .form_file-photos {
//     color: white;
//     background-color: $color-1;
//     display: inline-block;
//     cursor: pointer;
//     padding: 10px 20px;

//     -webkit-transition: background-color 0.3s ease-out; 
// 	-moz-transition: background-color 0.3s ease-out; 
// 	-o-transition: background-color 0.3s ease-out; 
// 	transition: background-color 0.3s ease-out;

//     &:hover {
//     	background-color: #8d7347;
//     }
// }

// .form_file-photos + .form__inputfile:focus,
// .form_file-photos:hover + .form__inputfile {
//     background-color: red;
// }

.js .input-file-container {
  position: relative;
  width: 100%;
}

.js .input-file-trigger {
  display: inline-block;
  padding: 10px;
  background-color: $color-1;
  color: $white;
  text-align: center;
  font-size: 1em;
	-webkit-transition: background-color 0.3s ease-out; 
	-moz-transition: background-color 0.3s ease-out; 
	-o-transition: background-color 0.3s ease-out; 
	transition: background-color 0.3s ease-out;
  cursor: pointer;

  &:hover {
  	background-color: #8d7347;
  }
}

.js .input-file {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
 
/* quelques styles d'interactions */
.js .input-file:hover + .input-file-trigger,
.js .input-file:focus + .input-file-trigger,
.js .input-file-trigger:hover,
.js .input-file-trigger:focus {
  background-color: #8d7347;
}
 
/* styles du retour visuel */
.file-return {
  margin: 0;
}
.file-return:not(:empty) {
  margin: 1em 0;
}
.js .file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
  margin-bottom: -6px;
}
/* on complète l'information d'un contenu textuel
   uniquement lorsque le paragraphe n'est pas vide */
.js .file-return:not(:empty):before {
  content: "Selected file: ";
  font-style: normal;
  font-weight: normal;
}

.contact--container__form {
	padding: 30px 0;
	margin-top: 20px;

	@include mq($until: desktop) {
		height: auto;
	}

	@include mq($from: desktop) {
		margin-top: 40px;
	}
	@include mq($from: wide) {
		margin-top: 60px;
	}
}

/***** FORM *****/
.form--container {
	width: 100%;
	margin: 0 auto;

	@include mq($from: tablet) {
		width: 70%;
	}
	@include mq($from: tablet) {
		width: 80%;
	}
	@include mq($from: wide) {
		width: 75%;
	}
	@include mq($from: xwide) {
		width: 70%;
	}
}

.form__content {
	overflow: hidden;
	clear: both;
}

.form__mention {
	padding-top: 10px;
}

.contact-form__intro {
	text-align: center;
	margin: 0 auto 2em auto;
	max-width: 80%;

	@include mq($from: desktop) {
		max-width: 70%;
		margin-bottom: 60px;
	}

	@include mq($from: wide) {
		max-width: 65%;
		margin-bottom: 100px;
	}

	p {
		margin-bottom: 20px;
	}

	a {
		color: $color-1;
		font-size: 16px;
		-webkit-transition: color 0.3s ease-out; 
		-moz-transition: color 0.3s ease-out; 
		-o-transition: color 0.3s ease-out; 
		transition: color 0.3s ease-out;

		&:hover {
			color: black;
		}
	}
}

.tel-contact {
	color: black;
	-webkit-transition: color 0.3s ease-out; 
	-moz-transition: color 0.3s ease-out; 
	-o-transition: color 0.3s ease-out; 
	transition: color 0.3s ease-out;

	&:hover {
		color: $color-1;
	}

}

.contact-form__intro--title {
	font-family: $font-bold;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	margin-bottom: 1.8em;

	&:after {
		display: block;
		content: "";
		width: 30px;
		height: 1px;
		background-color: $black;
		margin: 10px auto 0 auto;
	}
}

.form__layout {
	width: 100%;

	@include mq($from: desktop) {
		width: 50%;
		float: left;
	}

	&.form__layout--left {
		@include mq($from: desktop) {
			padding-right: 20px;
		}

		@include mq($from: wide) {
			padding-right: 30px;
		}
	}

	&.form__layout--right {
		@include mq($from: desktop) {
			padding-left: 20px;
		}

		@include mq($from: wide) {
			padding-left: 30px;
		}
	}

	li {
		display: block;
		margin-bottom: 10px;
	}

	.form__layout-inner {
		@include clearfix;
		height: auto;
		margin: 0;
	}
}

.form__input-container {
	position: relative;

	&:after {
		display: block;
		width: 16px;
		height: 16px;
		line-height: 16px;
		position: absolute;
		bottom: 10px;
		right: 10px;
		opacity: 0;
			
		background-color: $color-cancel;
		border-radius: 50%;

		text-align: center;
		content: "!";
		color: #fff !important;

		transition: opacity 0.2s ease-in-out;
	}

	&.error {
		border-color: $color-cancel;

		&:after {
			opacity: 1;
		}
	}
}

.form__label {
	display: block;
	padding: 8px 0;
	margin: 0;

	@include font-size(13);
	color: $color-1;
	color: darken($color-1, 10%);

	.no-touch & {
		-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
	}

	transition: color 0.2s ease-in-out;

	.current & {
		color: $black;
	}

	.error & {
		color: $color-cancel;
	}
}

.form__input {
	@include input_mobile();
	display: block;
	width: 100%;
	padding: 10px;

	font-family: $font-base;
	@include font-size(13);
	color: $color-text;

	border: 1px solid $color-1;
	background-color: transparent;

	transition: border-color 0.2s ease-in-out;

	

	&:focus {
		border-color: #000;
	}

	.error & {
		border-color: $color-cancel;
	}
}

.form__btn {
	margin: 1.5em 0 0 0;

	@include mq($from: desktop) {
		margin: 14px 0 0 0;
	}

	@include mq($from: xwide) {
		margin: 40px 0 0 0;
	}
	
	.btn {
		display: inline-block;
		padding: 10px 30px;
		position: relative;
		border: none;

		background-color: $color-1;

		font-family: $font-base;
		@include font-size(13);
		letter-spacing: 0.05em;
		color: $color-text-light;
		text-transform: uppercase;

		transition: background-color 0.2s ease-in-out;

		@include mq($from: xwide) {
			padding: 13px 40px;
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {

		.btn {
			background-color: darken($color-1, 10%);
		}
	}
}

.form__mention {
	@include font-size(11);
	font-style: italic;
	color: lighten($color-text, 20%);
}

/**** TARIFS ****/
.section--tarif {
	border: none;

	.wrapper {
		padding: 0;
	}
}

.tarif-content {
	background-color: $color-bg-dark;
	padding: 30px 20px;
	text-align: center;

	@include mq($from: tablet) {
		padding: 30px 0 40px;
	}

	@include mq($from: desktop) {
		padding: 30px 0 50px;
	}

	@include mq($from: wide) {
		padding: 55px 0 60px;
	}

	.tarif-content--intro {
		color: $color-1;
		margin: 20px auto 2em auto;

		@include mq($from: tablet) {
			max-width: 420px;
		}

		@include mq($from: desktop) {
			max-width: 470px;
			margin-bottom: 25px;
		}

		@include mq($from: wide) {
			max-width: 600px;
		}
	}

	.tarif-content--list-prices.detail {
		width: 100%;
	}

	.tarif-content--list-prices {
		@include clearfix;
		display: inline-block;
		width: auto;
		height: auto;
		margin-bottom: 80px;

		// .harmonize0 {
		// 	height: 250px;
		// }

		.price-item {
			position: relative;
			margin: 25px 0;
			padding: 15px;
			background-color: $white;
			width: 315px;
			max-width: 100%;
			border: 1px solid $color-1;
			border-color: lighten($color-1, 5%);

			&.price-item--detail {
				padding: 15px 15px 66px 15px;
			}

			@include mq($from: tablet) {
				float: left;
				margin: 25px;
				width: 240px;
			}

			@include mq($from: wide) {
				width: 315px;
			}

			header.price--header {
				background-color: $color-bg-dark;

				span {
					display: block;

					&.title-main {
						margin-bottom: 0.1em;
						font-family: $font-bold;
						@include font-size(24);
						letter-spacing: 0.05em;
						color: $color-1;
						padding: 10px 0;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;

						@include mq($from: wide) {
							font-size: 32px;
						}
					}

					&.title-alt {
						text-transform: uppercase;
						letter-spacing: 0.05em;
						@include font-size(13);

						@include mq($from: wide) {
							@include font-size(14);
						}
					}
				}
			}

			a{
				position: absolute;
				bottom:15px;
				left: 15px;
				right: 15px;
				font-family: $font-base;
				font-size: 13px;
				text-transform: uppercase;
				letter-spacing: 1px;
				display: block;
				padding: 15px 0;
				color: $white;
				z-index: 1;
				-moz-transition: all 0.3s;
				  -webkit-transition: all 0.3s;
				  transition: all 0.3s;
				&:hover {
					background-color: $color-5;

					&:after {
						content: "";
						width: 100%;
						opacity: 1;
					}
				}

				&:before {
					content: "";
					position: absolute;
				    top: 0;
				    right: 0;
				    bottom: 0;
				    left: 0;
				    margin: 0 auto;
				    background-color: $color-1;
				    z-index: -1;
				}

				&:after {
					content: "";
					position: absolute;
				    top: 0;
				    right: 0;
				    bottom: 0;
				    left: 0;
				    margin: 0 auto;
				   background-color: $color-5;

				    opacity: 0;
				    width: 0;
				    transform-origin: center;
				    z-index: -1;
				    -moz-transition: all 0.4s;
					  -webkit-transition: all 0.4s;
					  transition: all 0.4s;
				}
			}

			.price--detail-list {
				
				li {
					@include font-size(13);

					padding: 15px 0;
					&:nth-child(1) {
					}

					&:nth-child(2) {
						background-color: rgba($color-bg-dark, .4);
					}

					@include mq($from: wide) {
						@include font-size(14);
					}

					&:before {
						font-family: $font-icon;
						content: "\e905";
						@include font-size(14);
						color: $color-1;

						margin-right: 4px;
					}
				}
			}
		}
	}
}

.section--ref {
	margin-top: 90px;
	.title-lev1 {
		height: 100px;

		@include mq($from: mobileLandscape) {
			height: 60px;
		}
		@include mq($from: wide) {
			height: 100px;
		}
	}
}

.section--tarif {
	margin-top: 10px;
	
	&:first-of-type {
		margin-top: 90px;

		@include mq($until: desktop) {
			margin-top: 40px;
		}
	}

	.wrapper-pages {
		padding-bottom: 20px;

		@include mq($from: desktop) {
			padding-bottom: 40px;
		}

		@include mq($from: wide) {
			padding-bottom: 60px;
		}
	}

	.title-lev1{
		height: 80px;

		@include mq($from: mobileLandscape) {
			height: 60px;
		}
		@include mq($from: wide) {
			height: 100px;
		}
	}

	.banner-tarif--content {
		position: relative;
		width: 100%;
		height: 260px;

		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;

		@include mq($until: tablet) {
			height: 200px;
		}
		@include mq($from: desktop) {
			height: 230px;
		}
		@include mq($from: wide) {
			height: 260px;
		}
	}
}

/****
.section--tarif-forfait-invites {
	margin-top: 10px;

	.tarif-content--list-prices {
		> li {
			height: auto;
		}
	}
}
/**** ******/

.mfp-container {
	padding: 0 70px;

	@include mq($until: tablet) {
		padding: 0;
	}
}

.tarif {
// .mfp-bg {
// 	opacity: .9;
// }

.mfp-fade.mfp-bg {
  opacity: 0;
  background-color: $color-1;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}


.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.9;
}


.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: opacity 0.4s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

}



.cadre-detail-tarif {
	background-color: #AA8A58;
	padding: 0px;

	.white-popup-block {
		
		padding: 120px 0 50px 0px;
		//margin: 20px 0;
		.bouton-retour {
			display: inline-block;
			transition: color .5s, background-color .5s;
			color: #fff;
			font-size: 1.5rem;
			text-align: center;
			font-family: butlerlight,serif;
			padding: 5px 20px;

			border:1px solid $white;
			margin-left:20px;

			&:hover {
				background-color:  $white;
				color:  #AA8A58;
			} 

		}

		.title-lev1 {
			color: white;
			margin: 47px 0;
		}

		.tarif-content {
			background-color: white;
			padding: 55px 195px;

			@include mq($until: wide) {
				padding: 55px 70px;
			}

			@include mq($until: tablet) {
				padding: 25px 15px;
			}
		}

		.title-tarif-content{
			position: relative;
			font-family: $font-base;
			font-weight: 300;
			font-size: 28px;
			letter-spacing: 8px;
			text-transform: uppercase;
			color: $color-1;

			padding: 13px 0;

			&:after {
				content: "";
				position: absolute;
				bottom: 0; left: 0; right: 0;
				margin: 0 auto;
				width: 20px;
				height: 20px;
				border-bottom: 1px solid $color-1;
			}
		}

		.subtitle-lev {
			position: relative;
			display: block;
			font-family: $font-base;
			font-weight: 300;
			font-size: 22px;
			letter-spacing: 7px;
			text-transform: uppercase;
			color: black;
			
			margin-bottom: 30px;
			padding: 13px 0;

			&:after {
				content: "";
				position: absolute;
				bottom: 0; left: 0; right: 0;
				margin: 0 auto;
				width: 120px;
				height: 120px;
				border-bottom: 1px solid rgba(black, .2);
			}
		}

		h4 {
			position: relative;
			letter-spacing: 5px;
			text-align: left;
			padding-left: 40px;

			@include mq($until: desktop) {
				height: 60px;
			}

			&:before {
				content: "";
				position: absolute;
				top: 50%; left: 0;
				margin: 0 auto;
				width: 30px;
				height: 1px;
				background-color: $color-1;

				@include mq($until: desktop) {
					top: 30%;
				}

				@include mq($until: tablet) {
					top: 20%;
				}
			}
		}

		.col-1-2 {
			text-align: left;
			padding: 0;

			@include mq($until: desktop) {
				width: 100%;
			}

			&:first-child {
				padding-right: 35px;

				@include mq($until: desktop) {
					padding-right: 0;
				}
			}

			&:last-child {
				padding-left: 35px;

				@include mq($until: desktop) {
					padding-left: 0;
				}
			}

			p {
				line-height: 1.6;
			}
		}

		.banner-tarif--content {
			position: relative;
			width: 100%;
			height: 260px;

			background-size: cover;
			background-position: top center;
			background-repeat: no-repeat;

			@include mq($until: tablet) {
				height: 200px;
			}
			@include mq($from: desktop) {
				height: 230px;
			}
			@include mq($from: wide) {
				height: 260px;
			}
		}
	}
	.price--header-detail {
		display: block;
		width: 300px;
		height: 40px;
		margin: auto;
		margin-bottom: 30px;
		.title-main-detail {
			span {
				display: block;
			}
			margin-bottom: 0.1em;
			font-family: $font-bold;
			@include font-size(24);
			letter-spacing: 0.05em;
			color: $color-1;
			padding: 10px 0;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			@include mq($from: wide) {
				font-size: 32px;
			}
		}
	}

	@include mq($from: tablet) {
		.white-popup-block {
			padding-left: 50px;
			padding-right: 50px;
		}
		.white-popup-block {
			.bouton-retour {
				margin-left: 0;
			} 
		}
	}
}

button.mfp-arrow, button.mfp-close {
	margin-top: 110px;
	font-size: 50px;
	color: white !important;
	opacity: 1;
}

.section--contact {
	margin-top: 150px;

	.title-lev1 {
		height: 90px;
	}

	.block-inner--content {
		height: auto;
		margin-bottom: 50px;

		@include mq($until: tablet) {
			margin-bottom: 30px;
		}

		.col-1-2 {
			@include mq($until: wide) {
				width: 100%;
			}
		}
		
		.inner {
			position: initial;

			&:before {
				bottom:20px;
				left: 20px;
			}

			&:after {
				bottom: 20px;
				right: 20px;
			}
		}

		a {
			color: black;

			&:hover {
				color: $color-1;
			}

			&:before, &:after {
				display: none;
			}
		}
	}
}


/* 
   ###     ##         ########   ##     ##  ##     ##  
  ## ##    ##         ##     ##  ##     ##  ###   ###  
 ##   ##   ##         ##     ##  ##     ##  #### ####  
##     ##  ##         ########   ##     ##  ## ### ##  
#########  ##         ##     ##  ##     ##  ##     ##  
##     ##  ##         ##     ##  ##     ##  ##     ##  
##     ##  ########   ########    #######   ##     ##  
*/
.album-detail--container {
	padding-top: 80px;
}

.alt-contents {
	display: block;
	position: fixed;
	top: 90px;
	right: 15px;
	z-index: 10;

	@include mq($from: desktop) {
		top: 95px;
		right: 20px;
	}

	li {
		display: inline-block;

		&:first-of-type {
			margin-right: 12px;
		}

		a {
			display: block;
			position: relative;

			background-color: rgba($white, 0.7);
			padding: 4px 36px 4px 6px;

			border: 1px solid $color-border;
			border-color: rgba($color-border, 0.5);

			text-transform: uppercase;
			letter-spacing: 0.05em;
			@include font-size(11);
			color: $color-arrow;

			transition: color 0.2s ease-in-out;

			@include mq($from: tablet) {
				@include font-size(12);
			}

			&:before {
				font-family: 'emilie' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				text-indent: initial;
				display: block;
				color: $white;
				color: $color-arrow;
				@include font-size(24);
				transform: rotate(180deg);

				content: "\e902";
				right: 4px;
				top: -1px;
				position: absolute;

				transition: right 0.2s ease-in-out;

				@include mq($from: tablet) {
					@include font-size(26);
				}
			}

			&.current {
				padding: 4px 6px;

				// &:after {
				// 	display: block;
				// 	content: "";
				// 	width: 100%;
				// 	height: 1px;
				// 	background-color: $black;
				// 	background-color: rgba($black, 0.3);
				// }

				&:before {
					display: none;
				}
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				color: darken($color-1, 10%);
				border-color: darken($color-1, 10%);

				&:before {
					color: $color-1;
					right: -5px;

					@include mq($from: desktop) {
						right: -7px;
					}
				}
			}
		}
	}
}

.cd-intro--content {
	width: 100%;
	max-width: 100%;
    padding: 0 20px;
}

.cd-intro__title {
	margin: 0 0 1em 0;

	@include mq($from: mobileLandscape) {
		margin: 0 0 1.2em 0;
	}

	@include mq($from: tablet) {
		margin: 0 0 2em 0;
	}
}

.cd-album--title {
	position: relative;

	font-family: $font-base;
	text-transform: uppercase;
	letter-spacing: 0.25em;
	@include font-size(16);
	color: $white;
	line-height: 1.7em;

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	@include mq($from: mobileLandscape) {
		@include font-size(18);
		line-height: 2em;
	}

	@include mq($from: tablet) {
		@include font-size(24);
		letter-spacing: 0.3em;
	}

	@include mq($from: desktop) {
		@include font-size(26);
	}

	&:after {
		display: block;
	    content: "";
	    width: 12px;
	    height: 1px;
	    background-color: $white;
	    background-color: rgba($white, 0.8);
	    position: absolute;
	    bottom: -8px;
	    left: 50%;
	    margin-left: -6px;

		@include mq($from: mobileLandscape) {
			bottom: -10px;
		}

	    @include mq($from: tablet) {
	    	width: 20px;
	    }

	    @include mq($from: desktop) {
	    	width: 24px;
	    	margin-left: -12px;
	    	bottom: -12px;
	    }
	}
}

.cd-album--date {
    position: relative;
    font-family: $font-base;
    color: $color-text;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    @include font-size(16);
    line-height: 1.8em;

    @include mq($from: mobileLandscape) {
    	@include font-size(18);
		line-height: 2em;
	}

    @include mq($from: tablet) {
    	@include font-size(20);
    	letter-spacing: 0.3em;
    }

    @include mq($from: desktop) {
    	@include font-size(22);
    }
}

.cd-intro__description {
	position: relative;
	margin: 0 auto;
	max-width: 95%;
	padding: 11px 0 12px 0;

	border-color: rgba($color-text, 0.3);
	border-style: solid;
	border-width: 1px 0 1px 0;

	color: $color-text;

	@include mq($from: mobileLandscape) {
		max-width: 90%;
	}

	@include mq($from: tablet) {
		max-width: 70%;
		padding: 17px 0 20px 0;
	}

	@include mq($from: desktop) {
		max-width: 75%;
		padding: 20px 0 26px 0;
	}

	@include mq($from: wide) {
		max-width: 60%;
		padding: 24px 0 30px 0;
	}

	// @include mq($from: xwide) {
	// 	max-width: 45%;
	// }

	&:after {
		display: block;
		content: "";
		position: absolute;
		top: 100%;
		top: calc(100% + 1px);
		left: 50%;
		width: 1px;
		height: 20%;
		background-color: $black;
		background-color: rgba($black, 0.3);
	}

	p.cd-intro__presentation {
		line-height: 1.3em;
		margin: 0 auto 10px auto;

		text-align: center;

		@include mq($from: tablet) {
			margin: 0 auto 15px auto;
		}
		
		@include mq($from: desktop) {
			line-height: 1.4em;
			margin: 0 auto 18px auto;
		}

		@include mq($from: wide) {
			margin: 0 auto 24px auto;
		}

		@include mq($from: xwide) {
			max-width: 90%;
		}

		&:after {
			display: block;
			content: "";
			width: 12px;
			height: 1px;
			background-color: rgba($color-text, 0.5);

			margin: 10px auto 0 auto;

			@include mq($from: tablet) {
				width: 15px;
				margin: 15px auto 0 auto;
			}

			@include mq($from: desktop) {
				width: 20px;
				margin: 18px auto 0 auto;
			}

			@include mq($from: wide) {
				width: 24px;
				margin: 22px auto 0 auto;
			}
		}
	}

	.cd-intro__intervenant {
		@include clearfix;

		@include mq($from: desktop) {
			padding: 0 20px;
		}

		@include mq($from: wide) {
			padding: 0 30px;
		}

		li {
			margin: 0 0 0.25em 0;

			@include mq($from: tablet) {
				margin: 0 0 0.5em 0;
			}

			@include mq($from: desktop) {
				margin: 0 0 0.75em 0;

				width: 50%;
				float: left;

				text-align: left;
			}

			@include mq($from: wide) {
				margin: 0 0 1em 0;
			}

			&:nth-of-type(2n+1) {

				@include mq($from: desktop) {
					padding-right: 15px;
				}

				@include mq($from: wide) {
					padding-right: 20px;
				}
			}

			&:nth-of-type(2n) {

				@include mq($from: desktop) {
					padding-left: 15px;
				}

				@include mq($from: wide) {
					padding-left: 20px;
				}
			}

			&:last-of-type {
				margin: 0;
			}

			.intervenant-title {
				font-family: $font-bold;
				@include font-size(11);
				text-transform: uppercase;
				color: $color-text;
				letter-spacing: 0.2em;

				/* Better Font Rendering =========== */
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;

			    @include mq($from: desktop) {
			    	@include font-size(12);
			    }
			}
		}
	}
}


/* 
########   ########   ########   
##     ##  ##         ##         
##     ##  ##         ##         
########   ######     ######     
##   ##    ##         ##         
##    ##   ##         ##         
##     ##  ########   ##         
*/
.ref-link {
	display: block;
	min-height: 100px;
	border: 1px solid $color-1;

	text-align: center;
}

.ref-img {
	display: block;
	width: 100%;
	height: auto;
	
	-webkit-filter: saturate(0);
	-moz-filter: saturate(0);
	-o-filter: saturate(0);
	-ms-filter: saturate(0);
	filter: saturate(0);

	transition: filter 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		-webkit-filter: saturate(100%);
		-moz-filter: saturate(100%);
		-o-filter: saturate(100%);
		-ms-filter: saturate(100%);
		filter: saturate(100%);
	}
}


/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
.footer {

	.wrapper {
		@include mq($until: tablet) {
			margin: 10px 20px;
		}

		@include mq($from: tablet) {
			padding: 20px 0;
		}

		p {
			@include font-size(13);
			color: $color-1;

			@include mq($from: tablet) {
				@include font-size(12);
			}
		}
	}

	.album & {

		@include mq($until: tablet) {
			display: none;
		}
		// @include clearfix;
		// position: relative;

		// background-color: $white;
		// background-color: rgba($white, 0.8);
	}
}

/* 
 #######   ##     ##  ########    ######    ########   ####   #######   ##    ##   
##     ##  ##     ##  ##         ##    ##      ##       ##   ##     ##  ###   ##   
##     ##  ##     ##  ##         ##            ##       ##   ##     ##  ####  ##   
##     ##  ##     ##  ######      ######       ##       ##   ##     ##  ## ## ##   
##  ## ##  ##     ##  ##               ##      ##       ##   ##     ##  ##  ####   
##    ##   ##     ##  ##         ##    ##      ##       ##   ##     ##  ##   ###   
 ##### ##   #######   ########    ######       ##      ####   #######   ##    ##   
 */
/* 
########   ########   ########    #######   ##    ##    ######    ########   
##     ##  ##         ##     ##  ##     ##  ###   ##   ##    ##   ##         
##     ##  ##         ##     ##  ##     ##  ####  ##   ##         ##         
########   ######     ########   ##     ##  ## ## ##    ######    ######     
##   ##    ##         ##         ##     ##  ##  ####         ##   ##         
##    ##   ##         ##         ##     ##  ##   ###   ##    ##   ##         
##     ##  ########   ##          #######   ##    ##    ######    ########   
 */

.request-categorie{

	li{

		transition: border-color 0.3s ease-in-out;

		background-color: #fff;
		margin:0 0 10px 0;
		padding:10px;
		
		font-weight: 700;
		text-transform: uppercase;
		color:$color-1;

		border:1px solid $white;

		cursor: pointer;

		.no-touchevents &:hover, .no-touchevents &:focus, .touchevents &:active,
		&.active {
			border-color:$color-1;
		}

	}

}

.request-answers{
	position: relative;
}

.request-question-list{
	height: 0;
	visibility: hidden;

	&.active{
		height:auto;
		visibility: visible;
	}
}

.request-header{

	position: relative;
	transition: color 0.3s ease-in-out;

	border:1px solid $color-1;
	border-width:0 0 1px 0;

	padding:12px 30px 12px 0;

	cursor: pointer;

	[class^="icon-"]{
		transition: color 0.3s ease-in-out;
		position: absolute;
		top: 50%;
		right: 10px;
		transform:translate(0,-50%);
		@include font-size(20);
		color:rgba($black,.3);
		color:$color-1;

		transition: transform 0.2s ease-in-out;
	}

	&.opened {
		[class^="icon-"] {
			transform: translate(0,-50%) rotate(135deg);
		}
	}

	.no-touchevents &:hover, .no-touchevents &:focus, .touchevents &:active {
		color:$color-1;
		[class^="icon-"]{ color:$color-1; }
	}

}

.request-item{
	transition: transform 0s ease-in-out, opacity 0s ease-in-out;
	transform: translate(0,100%);
	opacity: 0;

	.active &{
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
		transform: translate(0,0);
		opacity: 1;
	}
}

$line : 20;

$delay : 50ms;

@for $i from 1 through $line{
	.active .request-item:nth-of-type( #{$i} ){ transition-delay: $i * $delay; }
}

.request-answer{
	display: none;
	padding:20px;
}